<template>
  <div
    v-if="this.beneficiary === null"
    id="modal_popup_loading-beneficiary"
    class="modal-popup"
  >
    <div class="modal-popup-content">
      <img src="@/assets/images/loading.gif" />
      <p v-html="$t('loadingPopupMessage')"></p>
    </div>
  </div>
  <div v-else>
    <div class="beneficary-details-page">
      <div class="left">
        <h1>
          {{ this.beneficiary.id }} -
          {{ this.beneficiary.last_name + " " + this.beneficiary.first_name }}
        </h1>
      </div>
      <div class="right">
        <a
          v-if="this.beneficiary.active"
          class="notification-action custom-notification-action"
          @click="
            showModalPopup(
              'modal_popup_actionRequest',
              true,
              null,
              'INACTIVATE',
              true
            )
          "
          >{{ $t("suspendUserLabel") }}</a
        >
        <a
          class="notification-action disabled custom-notification-action"
          @click="
            showModalPopup(
              'modal_popup_actionRequest',
              true,
              null,
              'DELETE',
              true
            )
          "
          >{{ $t("deleteUserLabel") }}</a
        >
      </div>
      <br clear="all" />
      <div v-if="!this.beneficiary.active" class="notification desktop">
        <table cellpadding="10" cellspacing="0" width="95%" align="center">
          <tr>
            <td width="7%" valign="top">
              <img src="@/assets/images/alerte.svg" />
            </td>
            <td width="75%" valign="top">
              <h2>{{ $t("suspendedBeneficiaryTitle") }}</h2>
              <p class="gri">{{ $t("suspendedBeneficiaryMessage") }}</p>
            </td>
            <td width="18%">
              <a
                class="notification-action"
                @click="
                  showModalPopup(
                    'modal_popup_actionRequest',
                    true,
                    null,
                    'ACTIVATE',
                    true
                  )
                "
                >{{ $t("reactivateBeneficiaryButtonLabel") }}</a
              >
            </td>
          </tr>
        </table>
      </div>
      <br />
      <div :class="!this.beneficiary.active && 'benef'">
        <div v-if="!this.beneficiary.active" class="benef-inactive"></div>
        <div class="notification desktop" v-if="noOfPinRequests > 0">
          <table cellpadding="10" cellspacing="0" width="95%" align="center">
            <tr>
              <td width="7%" valign="top">
                <img src="@/assets/images/alerte.svg" />
              </td>
              <td width="65%" valign="top">
                <h2>
                  {{
                    $t("reissuePINBeneficiaryTitle", {
                      count: this.noOfPinRequests
                    })
                  }}
                </h2>
                <p class="gri">{{ $t("reissuePINBeneficiaryMessage") }}</p>
              </td>
            </tr>
          </table>
        </div>
        <div class="notification desktop" v-if="noOfCardRequests > 0">
          <table cellpadding="10" cellspacing="0" width="95%" align="center">
            <tr>
              <td width="7%" valign="top">
                <img src="@/assets/images/alerte.svg" />
              </td>
              <td width="65%" valign="top">
                <h2>
                  {{
                    $t("reissueCardBeneficiaryTitle", {
                      count: this.noOfCardRequests
                    })
                  }}
                </h2>
                <p class="gri">{{ $t("reissuePINBeneficiaryMessage") }}</p>
              </td>
            </tr>
          </table>
        </div>

        <br />
        <div class="container-alb">
          <table width="95%" align="center" cellpadding="10" cellspacing="0">
            <tr>
              <td align="left">
                <small>{{ $t("lastNameField") }}</small>
                <br />
                <input
                  id="beneficiaryLastName"
                  type="text"
                  v-model="formData.lastName"
                  class="formedituser"
                  disabled
                  @keyup="updateInputCharacters()"
                />
              </td>
              <td align="left">
                <small>{{ $t("surnameFieldLabel") }}</small>
                <br />
                <input
                  type="text"
                  id="beneficiaryFirstName"
                  v-model="formData.firstName"
                  class="formedituser"
                  disabled
                  @keyup="updateInputCharacters()"
                />
              </td>
            </tr>

            <tr>
              <td align="left">
                <small>{{ $t("mobileField") }}</small>
                <br />
                <input
                  type="text"
                  id="beneficiaryMobilePhone"
                  v-model="formData.mobilePhone"
                  class="formedituser"
                  disabled
                  @keyup="updateInputCharacters()"
                />
              </td>
              <td align="left">
                <small>{{ $t("cnpFieldLabel") }}</small>
                <br />
                <input
                  type="text"
                  :value="beneficiary.security_code"
                  class="formedituser"
                  disabled
                  @keyup="updateInputCharacters()"
                />
              </td>
            </tr>
            <tr>
              <td align="left">
                <small>{{ $t("emailField") }}</small>
                <br />
                <input
                  type="text"
                  id="beneficiaryEmail"
                  v-model="formData.email"
                  class="formedituser"
                  disabled
                />
              </td>
            </tr>
          </table>

          <table cellpadding="10" cellspacing="0" width="95%" align="center">
            <tr v-if="!this.editMode">
              <td>
                <a
                  class="notification-action custom-notification-action"
                  @click="editBeneficiaryDetails()"
                  >{{ $t("editButtonLabel") }}</a
                >
              </td>
            </tr>
            <tr v-if="this.editMode">
              <td>
                <a
                  class="notification-action"
                  @click="submitBeneficiaryDetails()"
                  >{{ $t("saveChangesLabel") }}</a
                >
              </td>
              <td>
                <a class="resetButton" @click="resetBeneficiaryDetails()">{{
                  $t("cancelChangesLabel")
                }}</a>
              </td>
            </tr>
          </table>

          <br clear="all" />
        </div>
        <div
          v-for="(entry, index) in this.beneficiary.accounts"
          :key="index"
          class="box-left margin-bottom-10"
          :class="index % 2 === 1 && 'clear-none'"
        >
          <div class="container-alb">
            <div class="usage-volume-container">
              <img
                :src="
                  require('@/assets/images/logo-up-' + 
                  (entry.product > 1500 ? '15' : entry.product)+
                   '.png')
                "
              />
              <br />
              <br />
              <div v-if="entry.card && entry.card !== null">
                <p>{{ $t("deliveryPointLabel") }}</p>
                <br />
                <select class="filtrare" v-model="entry.card.addressCode">
                  <option
                    v-for="(delivery, index) in deliveryAddresses"
                    :key="index"
                    :value="delivery.codcom"
                    :selected="delivery.codcom === entry.card.addressCode"
                  >
                    {{
                      delivery.codcom +
                      " - " +
                      delivery.address.street_name +
                      ", " +
                      delivery.address.locality +
                      ", " +
                      delivery.address.county
                    }}
                  </option>
                </select>
                <br />
                <div class="grey-card">
                  <p align="center">
                    {{ $t("cardNumberLabel") }}
                    {{ entry.card && entry.card.pan }}
                    <br />
                    {{ $t("beneficiariesTableCardStatus") + ": " }}
                    {{ $t("cardStatus_" + entry.card.state) }}
                    <br />
                    {{ $t("beneficiariesTableCardType") + ": " }}
                    {{ entry.card.virtual ? $t('beneficiariesTableCardTypeVirtual') : $t('beneficiariesTableCardTypePhysical' ) }}
                    <br />
                    {{
                      entry.card &&
                      $t("cardExpiryLabel") + " " + entry.card.expire
                    }}
                    <br />
                    {{
                      (customer.see_sold)
                        ? $t("currentBalanceLabel") +
                          " " +
                          entry.spent +
                          " " +
                          $t("currency")
                        : ""
                    }}
                  </p>
                  <br />
                  <div class="text-center">
                    <template v-for="(event, index) in  entry.card.events">
                      <span :key="index" class="status-background" :class="getClassForCardStatus(event.type)">{{
                        $t("cardStatus_" + event.type) }}</span>
                      </template>
                    </div>
                    <br />
                  <div class="text-center">
                    <a
                      v-if="
                        (entry.card.state === 'X' ||
                        entry.card.state === 'B' ||
                        entry.card.state === 'D' ||
                        entry.card.state === 'L' ||
                        entry.card.state === 'F' ||
                        entry.card.state === 'OH') &&
                        !(accountHasReissueRequest(entry, 'RV') || accountHasReissueRequest(entry, 'RC'))
                      "
                      class="notification-outline"
                      @click="
                        showModalPopup(
                          'modal_popup_confirmCardRequest',
                          true,
                          entry,
                          'REPL',
                          true
                        )
                      "
                      >{{ $t("reissueCardButtonLabel") }}</a
                    >
                    <a
                      v-if="
                        (entry.card.state === 'A' || entry.card.state === 'C') && !entry.card.virtual && !accountHasReissueRequest(entry, 'RP')
                      "
                      class="notification-outline"
                      @click="
                        showModalPopup(
                          'modal_popup_actionRequest',
                          true,
                          entry,
                          'PIN',
                          true
                        )
                      "
                      >{{ $t("reissuePINButtonLabel") }}</a
                    >
                    <a
                      v-if="
                        (entry.card.state === 'A' || entry.card.state === 'C') && entry.card.virtual && !accountHasReissueRequest(entry, 'RM')
                      "
                      class="notification-outline"
                      @click="
                        showModalPopup(
                          'modal_popup_actionRequest',
                          true,
                          entry,
                          'CLO',
                          true
                        )
                      "
                      >{{ $t("issueCardButtonLabel") }}</a
                    >
                    <a
                      v-if="
                        entry.card.state === 'A' &&(
                        customer.block_cards 
                      )
                      "
                      class="notification-outline"
                      @click="
                        showModalPopup(
                          'modal_popup_actionRequest',
                          true,
                          entry,
                          'BLOK',
                          true
                        )
                      "
                      >{{ $t("blockCardButtonLabel") }}</a
                    >
                    <a
                      v-if="entry.card.state === 'C'"
                      class="notification-outline"
                      @click="
                        showModalPopup(
                          'modal_popup_actionRequest',
                          true,
                          entry,
                          'ACT',
                          true
                        )
                      "
                      >{{ $t("activateCardButtonLabel") }}</a
                    >
                    <a
                      v-if="accountHasReissueRequest(entry, 'RP')"
                      class="notification-outline"
                      @click="
                        showModalPopup(
                          'modal_popup_actionRequest',
                          true,
                          entry,
                          'CANCEL_PIN',
                          true
                        )
                      "
                      >{{ $t("cancelReissuePINButtonLabel") }}</a
                    >
                    <a
                    v-if="accountHasReissueRequest(entry, 'RC') || accountHasReissueRequest(entry, 'RV')"
                    class="notification-outline"
                      @click="
                        showModalPopup(
                          'modal_popup_actionRequest',
                          true,
                          entry,
                          'CANCEL_REPL',
                          true
                        )
                      "
                      >{{ $t("cancelReissueCardButtonLabel") }}</a
                    >
                    <a
                      v-if="accountHasReissueRequest(entry, 'RM')"
                      class="notification-outline"
                      @click="
                        showModalPopup(
                          'modal_popup_actionRequest',
                          true,
                          entry,
                          'CANCEL_CLO',
                          true
                        )
                      "
                      >{{ $t("cancelIssueCardButtonLabel") }}</a
                    >
                    <a
                      v-if="accountHasReissueRequest(entry, 'RP')"
                      class="notification-outline"
                      @click="
                        showModalPopup(
                          'modal_popup_actionRequest',
                          true,
                          entry,
                          'VALIDATE_PIN',
                          true
                        )
                      "
                      >{{ $t("validateReissuePINButtonLabel") }}</a
                    >
                    <a
                      v-if="accountHasReissueRequest(entry, 'RM') "
                      class="notification-outline"
                      @click="
                        showModalPopup(
                          'modal_popup_actionRequest',
                          true,
                          entry,
                          'VALIDATE_CLO',
                          true
                        )
                      "
                      >{{ $t("validateIssueCardButtonLabel") }}</a
                    >
                    <a
                      v-if="accountHasReissueRequest(entry, 'RV') || accountHasReissueRequest(entry, 'RC')"
                      class="notification-outline"
                      @click="
                        showModalPopup(
                          'modal_popup_confirmCardRequest',
                          true,
                          entry,
                          'VALIDATE_REPL',
                          true
                        )
                      "
                      >{{ $t("validateReissueCardButtonLabel") }}</a
                    >
                  </div>

                  <div class="notificare">
                    <div></div>
                    <div>{{ $t("notificationsChooseLabel") }}</div>
                    <div>
                      <div class="custom-control custom-switch">
                        <input
                          type="checkbox"
                          :id="'customSwitch' + entry.id"
                          class="custom-control-input"
                          :checked="entry.sms === 'Y'"
                          @change="smsChecked($event.target, entry)"
                        />
                        <label
                          class="custom-control-label"
                          :for="'customSwitch' + entry.id"
                        ></label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container-alb scroll-table">
          <table cellpadding="10" cellspacing="0" width="95%" align="center">
            <tr>
              <td>
                <h3>{{ $t("beneficiaryOrderHistory") }}</h3>
              </td>
            </tr>
          </table>

          <table
            v-if="this.historyDetails !== null"
            cellpadding="10"
            cellspacing="0"
            width="95%"
            class="hover-table"
            align="center"
          >
            <tr>
              <td @click="() => (sortBy = 'date')" valign="middle">
                <strong>{{ $t("ordersTableHeaderDate") }}</strong
                >&nbsp;
                <img
                  src="@/assets/images/arrow-down-fill.svg"
                  @click="sortBy === 'date' && changeReverse()"
                  :style="
                    sortBy === 'date' && reverse && 'transform: rotate(180deg)'
                  "
                />
              </td>
              <td @click="() => (sortBy = 'product')" valign="middle">
                <strong>{{ $t("ordersTableHeaderProduct") }}</strong
                >&nbsp;
                <img
                  src="@/assets/images/arrow-down-fill.svg"
                  @click="sortBy === 'product' && changeReverse()"
                  :style="
                    sortBy === 'product' &&
                    reverse &&
                    'transform: rotate(180deg)'
                  "
                />
              </td>
              <td
                @click="() => (sortBy = 'order_type')"
                class="priority-3"
                valign="middle"
              >
                <strong>{{ $t("ordersTableHeaderType") }}</strong
                >&nbsp;
                <img
                  src="@/assets/images/arrow-down-fill.svg"
                  @click="sortBy === 'order_type' && changeReverse()"
                  :style="
                    sortBy === 'order_type' &&
                    reverse &&
                    'transform: rotate(180deg)'
                  "
                />
              </td>
              <td class="priority-4" valign="middle">
                <strong>{{ $t("ordersTableHeaderAmount") }}</strong>
              </td>
              <td class="priority-5" valign="middle">
                <strong>{{ $t("ordersTableHeaderNumber") }}</strong>
              </td>
            </tr>
            <template v-for="(entry, index) in this.filterHistory">
              <tr
                :key="index"
                class="trhover parent"
                :class="index % 2 === 0 && 'gri-cell'"
                @click="selectHistoryDetailsMobile(index)"
              >
                <td>
                  <a
                    class="detailsOrderMobile priority-show-5 display-inline-block"
                  >
                    <font-awesome-icon
                      icon="minus-circle"
                      v-if="detailsMobile && entry === selectedHistoryDetails"
                    />
                    <font-awesome-icon icon="plus-circle" v-else />&nbsp;&nbsp;
                  </a>
                  {{ displayDate(entry.date) }}
                </td>
                <td>
                  <img
                    :src="
                      require('@/assets/images/bullet_' +
                      (entry.product > 1500 ? '15' : entry.product)+

                        '.svg')
                    "
                  />
                  &nbsp;&nbsp;{{ getProductNameMethod(entry.product) }}
                </td>
                <td class="priority-3">
                  {{ $t("orderType_" + entry.order_type) }}
                </td>
                <td class="priority-4">{{ entry.description }}</td>
                <td class="priority-5">
                  <a
                    class="see-more color-white"
                    :href="'/customer/orders?order_id=' + entry.order_id"
                    >{{ $t("beneficiariesTableSeeMore") }}</a
                  >
                </td>
              </tr>
              <tr
                :key="index + filterHistory.length"
                class="child"
                :class="index % 2 === 0 && 'gri-cell'"
                v-show="detailsMobile && entry === selectedHistoryDetails"
              >
                <td colspan="12">
                  <p class="priority-show-3">
                    <strong
                      >{{ $t("ordersTableHeaderType") }} : &nbsp;&nbsp;</strong
                    >
                    <span>{{ $t("orderType_" + entry.order_type) }}</span>
                  </p>
                  <p class="priority-show-4">
                    <strong>
                      {{ $t("ordersTableHeaderAmount") }} : &nbsp;&nbsp;
                    </strong>
                    <span>{{ entry.description }}</span>
                  </p>
                  <p class="priority-show-5">
                    <strong>ID : &nbsp;&nbsp;</strong>
                    <a
                      class="see-more color-white"
                      :href="'/customer/orders?order_id=' + entry.order_id"
                      >{{ $t("beneficiariesTableSeeMore") }}</a
                    >
                  </p>
                </td>
              </tr>
            </template>
          </table>
        </div>
      </div>
    </div>
    <div id="modal_popup_actionRequest" class="modal-popup">
      <div
        v-if="this.action && !this.actionError && !this.actionSuccess"
        class="modal-popup-content"
      >
        <a
          @click="
            showModalPopup('modal_popup_actionRequest', false, null, 'NO')
          "
          class="close-button"
        >
          <img src="@/assets/images/close-button.svg" />
        </a>
        <p
          v-if="this.choosenAccount !== null"
          v-html="
            $t('confirmActionBeneficiary_' + this.action, {
              product: this.getProductNameMethod(this.choosenAccount.product)
            })
          "
        ></p>
        <p
          v-if="this.choosenAccount === null"
          v-html="$t('confirmActionBeneficiary_' + this.action)"
        ></p>
        <div class="buttons">
          <a class="yesButton" @click="confirmAction()">{{
            $t("notificationsChooseOptionYes")
          }}</a>
          <a class="noButton" @click="confirmActionNot()">{{
            $t("notificationsChooseOptionNo")
          }}</a>
        </div>
      </div>
      <div
        v-if="this.choosenAccount && !this.actionError && this.actionSuccess"
        class="modal-popup-content"
      >
        <a
          @click="
            showModalPopup('modal_popup_actionRequest', false, null, 'NO')
          "
          class="close-button"
        >
          <img src="@/assets/images/close-button.svg" />
        </a>
        <p
          v-html="
            $t('successActionBeneficiary_' + this.action, {
              product: this.getProductNameMethod(this.choosenAccount.product)
            })
          "
        ></p>
      </div>
      <div v-if="this.actionError" class="modal-popup-content">
        <a
          @click="
            showModalPopup('modal_popup_actionRequest', false, null, 'NO')
          "
          class="close-button"
        >
          <img src="@/assets/images/close-button.svg" />
        </a>
        <p v-html="$t('errorActionBeneficiary_' + this.actionError.code)">
        </p>
      </div>
    </div>
    <div id="modal_popup_confirmCardRequest" class="modal-popup">
      <div class="modal-popup-content">
        <a
          @click="
            showModalPopup('modal_popup_confirmCardRequest', false, null, 'NO')
          "
          class="close-button"
        >
          <img src="@/assets/images/close-button.svg" />
        </a>
        <p v-html="$t('confirmCardReqMsg')"></p>
        <div class="buttons">
          <a href="#" class="yesButton" @click="reissueRequest(true)">{{$t('cardVirtualButtonLabel')}}</a>
          <a href="#" class="noButton" @click="reissueRequest(false)">{{$t('cardPhysicalButtonLabel')}}</a>
        </div>
      </div>
    </div>
    <div id="modal_popup_virtual_details" class="modal-popup">
      <div class="modal-popup-content">
        <a @click="showModalPopup('modal_popup_virtual_details', false, null, 'NO')" class="close-button">
          <img src="@/assets/images/close-button.svg" />
        </a>
        <p v-html="virtualReissue ? $t('confirmCardReqVirtualMsg') : $t('confirmCardReqPhysicalMsg')"></p>
        <a class="button-orange" @click="confirmAction()">{{
          $t("nextStepButtonLabel")
        }}</a>
        <a class="button-orange" @click="cancelReissueRequest()">{{
          $t("cancelModifyLabel")
        }}</a>
      </div>
    </div>
    <div id="modal_popup_info" class="modal-popup">
      <div class="modal-popup-content">
        <a
          @click="
            refresh ? refreshPage() : showModalPopup('modal_popup_info', false)
          "
          class="close-button"
        >
          <img src="@/assets/images/close-button.svg" />
        </a>
        <p
          v-if="this.actionError"
          v-html="$t('errorActionBeneficiary_' + this.actionError.code)"
        ></p>
        <p  v-if="this.beneficiaryDataError && this.beneficiaryDataError.code " >
                {{ this.config.errors[this.beneficiaryDataError.code] }}
              </p>
        <div v-if="this.actionOrderErrors">
          <p
            v-for="(error, index) in this.actionOrderErrors"
            :key="index"
            class="trhover"
            :class="index % 2 === 0 && 'gri-cell'"
          >
            {{ $t(error.error_code, { name: error.from_source_id }) }}
          </p>
        </div>
        <p v-if="this.actionSuccess">{{ this.successMessage }}</p>
      </div>
    </div>
    <div id="modal_popup_loading" class="modal-popup">
      <div class="modal-popup-content">
        <img src="@/assets/images/loading.gif" />
        <p v-html="$t('loadingPopupMessage')"></p>
      </div>
    </div>
  </div>
</template>

<script>
import httpServiceCustomer from "@/http/http-customer.js";
import utils from "@/plugins/utils.js";
import moment from "moment";
import Cookies from "js-cookie";
import $ from "jquery";

export default {
  props: {
    beneficiaryId: Number,
    customer: Object,
    deliveryAddresses: Array,
    msg: String,
    user: Object,
    userRole: String
  },
  data() {
    return {
      beneficiary: null,
      historyDetails: null,
      choosenAccount: null,
      action: null,
      actionError: null,
      beneficiaryDataError: null,
      actionOrderErrors: null,
      successMessage: null,
      actionSuccess: false,
      formData: null,
      editMode: false,
      detailsMobile: false,
      selectedHistoryDetails: null,
      search: "",
      sortBy: "date",
      reverse: true,
      refresh: false,
      virtualReissue: true,
      config: null,


    };
  },
  methods: {
    getBeneficiaryDetails() {
      httpServiceCustomer.customerService
        .getBeneficiaryDetails(
          this.customer.company_id,
          this.beneficiaryId,
          this.accessToken
        )
        .then(function(response) {
          return response;
        })
        .catch((error) => {
          this.showModalPopup("modal_popup_loading", false, null, this.action);
        })
        .then((response) => {
          if (response.status == 200) {
            this.beneficiary = response.data;
            /* this.beneficiary.accounts[0].card.state = 'A';
            this.beneficiary.accounts[0].card.virtual = true;
*/
            this.resetBeneficiaryDetails();
            this.$forceUpdate();
            this.showModalPopup("modal_popup_loading", false, null, this.action);
          } else if (response.status == 400 || response.status == 403) {
            this.customerBeneficiaries = null;
          }
        });
    },
    getBeneficiaryHistory() {
      httpServiceCustomer.customerService
        .getBeneficiaryHistory(
          this.customer.company_id,
          this.beneficiaryId,
          this.accessToken
        )
        .then(function(response) {
          return response;
        })
        .catch((error) => {
          this.showModalPopup("modal_popup_loading", false, null, this.action);
          this.historyDetails = null;
        })
        .then((response) => {
          if (response.status == 200) {
            this.historyDetails = response.data;
          } else {
            this.historyDetails = null;
          }
        });
    },
    getProductNameMethod(productId) {
      return utils.getCompanyProductName(productId, this.customer.products);
    },
    displayDate(date) {
      return moment(new Date(date), "YYYY-MM-DDThh:mm:ss").format("DD/MM/YYYY");
    },
    smsChecked(element, entry) {
      this.actionSuccess = false;
      if (element.checked) {
        entry.sms = "Y";
      } else {
        entry.sms = "N";
      }
      this.showModalPopup("modal_popup_actionRequest", true, entry, "SMS");
    },
    showModalPopup(
      elementId,
      show,
      entity = null,
      action = null,
      resetError = false
    ) {
      if (action + this.action === "NOSMS") {
        for (let index in this.beneficiary.accounts) {
          if (this.beneficiary.accounts[index].id === this.choosenAccount.id) {
            this.beneficiary.accounts[index].sms =
              this.choosenAccount.sms === "" ? "N" : this.choosenAccount.sms;
          }
        }
        this.choosenAccount = null;
      }
      if (resetError) {
        this.actionError = null;
        this.beneficiaryDataError = null;
        this.actionSuccess = false;
      }
      this.actionOrderErrors = null;
      if (action !== "NO") {
        this.action = action;
      }
      if (show) {
        this.choosenAccount = entity;
        document.getElementById(elementId).style.visibility = "visible";
      } else {
        this.choosenAccount = null;
        document.getElementById(elementId).style.visibility = "hidden";
      }
    },
    reissueRequest(isVirtual){
      this.virtualReissue = isVirtual;
      this.action = "REPL";
      this.showModalPopup("modal_popup_virtual_details", true, this.choosenAccount,
      this.action);
    },
    cancelReissueRequest(){
      this.virtualReissue = true;
      this.showModalPopup("modal_popup_virtual_details", false, this.choosenAccount,"NO");
    },
    confirmAction() {
      this.showModalPopup(
        "modal_popup_loading",
        true,
        this.choosenAccount,
        this.action
      );
      if (this.action === "SMS") {
        let account = {
          id: this.choosenAccount.id,
          sms: this.choosenAccount.sms
        };
        httpServiceCustomer.customerService
          .modifyAccountData(
            this.customer.company_id,
            this.beneficiary.id,
            account,
            this.accessToken
          )
          .then((response) => {
            if (response.status === 200) {
              this.actionSuccess = true;
              this.actionError = null;
              this.successMessage = this.$t("actionSuccessMessage", {
                action: this.$t("cardsAction_" + this.action)
              });
            }
            this.getBeneficiaryDetails();
            this.showModalPopup(
              "modal_popup_loading",
              false,
              null,
              this.action
            );
            this.showModalPopup(
              "modal_popup_actionRequest",
              false,
              null,
              this.action
            );
            this.showModalPopup("modal_popup_info", true, null, this.action);
          })
          .catch((error) => {
            if (error.response.data.code) {
              this.actionError = error.response.data;
              this.actionSuccess = false;
            } else if (error.response.status === 409) {
              this.actionError = { code: "409" };
            } else {
              this.actionError = { code: "500" };
            }
            this.showModalPopup(
              "modal_popup_loading",
              false,
              null,
              this.action
            );
            this.showModalPopup(
              "modal_popup_actionRequest",
              false,
              null,
              this.action
            );
            this.choosenAccount.sms === "Y"
              ? (this.choosenAccount.sms = "N")
              : (this.choosenAccount.sms = "Y");

            this.showModalPopup("modal_popup_info", true, null, this.action);
          });
      } else if (
        this.action === "PIN" ||
        this.action === "REPL" ||
        this.action === "CLO" ||
        this.action === "VALIDATE_PIN" ||
        this.action === "VALIDATE_REPL" ||
        this.action === "VALIDATE_CLO" ||
        this.action === "CANCEL_PIN" ||
        this.action === "CANCEL_REPL" ||
        this.action === "CANCEL_CLO" ||
        this.action === "ACT" ||
        this.action === "BLOK"
      ) {
        this.refresh = false;
        let cardsActionData = {
          id: 0,
          type: this.action, //"PIN" reemitere PIN
          //"REPL" /  reemitere CARD
          //"CLO" /  emitere CARD FIZIC PT CARD VIRTUAL
          customer_id: this.customer.company_id,
          product: (this.choosenAccount.product + "").substring(0, 2),
          details: [
            {
              card_id: this.choosenAccount.card.id,
              address: this.choosenAccount.card.addressCode,
              card_virtual : (this.action === "REPL" || this.action === "VALIDATE_REPL") ? this.virtualReissue : this.choosenAccount.card.virtual
            }
          ]
        };
        httpServiceCustomer.customerService
          .cardsAction(
            cardsActionData,
            this.accessToken,
            this.userRole === "FAC" ? this.user.username : null
          )
          .then(function(response) {
            return response;
          })
          .then((response) => {
            if (response.status === 200) {
              this.actionSuccess = true;
              this.actionError = null;
              this.successMessage = this.$t("actionSuccessMessage", {
                action: this.$t("cardsAction_" + this.action)
              });
              this.refresh = true;
            }
            this.showModalPopup(
              "modal_popup_loading",
              false,
              null,
              this.action
            );
            this.showModalPopup(
              "modal_popup_actionRequest",
              false,
              null,
              this.action
            );
            this.showModalPopup(
              "modal_popup_confirmCardRequest",
              false,
              null,
              this.action
            );
            this.showModalPopup('modal_popup_virtual_details', false, null, this.action);

            this.showModalPopup("modal_popup_info", true, null, this.action);
            // setTimeout(() => {
            //   this.beneficiary = null;
            //   this.getBeneficiaryDetails();
            // }, 1000);
          })
          .catch((error) => {
            this.getResponseErrors(error.response);
            this.showModalPopup(
              "modal_popup_actionRequest",
              false,
              null,
              this.action
            );
            this.showModalPopup(
              "modal_popup_confirmCardRequest",
              false,
              null,
              this.action
            );
            this.showModalPopup('modal_popup_virtual_details', false, null, this.action);
          });
      } else if (this.action === "DELETE") {
        this.showModalPopup("modal_popup_loading", false, null, this.action);
        this.deleteBeneficiary();
      } else if (this.action === "INACTIVATE" || this.action === "ACTIVATE") {
        this.showModalPopup("modal_popup_loading", false, null, this.action);
        this.submitBeneficiaryDetails();
      }
    },
    confirmActionNot() {
      if (this.action == "SMS") {
        this.choosenAccount.sms === "Y"
          ? (this.choosenAccount.sms = "N")
          : (this.choosenAccount.sms = "Y");
      }
      this.showModalPopup("modal_popup_actionRequest", false, null, "NO");
    },
    getResponseErrors(response) {
      let errorsLink = response.headers["link"].split(";")[0];
      errorsLink = errorsLink.replace(">", "");
      errorsLink = errorsLink.replace("<", "");
      if (errorsLink.includes("exceptions_type")) {
        httpServiceCustomer.customerService
          .getOrderErros(errorsLink, this.accessToken)
          .then((response) => {
            if (response.status === 200) {
              this.actionOrderErrors = response.data.errors;
              this.error = { code: "ERROR" };
            }
          })
          .catch((error) => {
            if (error.response.data) {
              this.actionSuccess = false;
              this.error = { code: error.response.data.code };
            } else if (error.response.status === 409) {
              this.error = { code: "409" };
            } else {
              this.error = { code: "500" };
            }
          });
      }
      this.showModalPopup("modal_popup_loading", false, null, this.action);
      this.showModalPopup(
        "modal_popup_actionRequest",
        false,
        null,
        this.action
      );
      this.showModalPopup(
        "modal_popup_confirmCardRequest",
        false,
        null,
        this.action
      );
      this.showModalPopup('modal_popup_virtual_details', false, null, this.action);
      this.showModalPopup("modal_popup_info", true, null, this.action);
    },
    editBeneficiaryDetails() {
      this.editMode = true;
      this.action = "MODIFY_DATA";
      $("#beneficiaryFirstName").prop("disabled", false);
      $("#beneficiaryLastName").prop("disabled", false);
      $("#beneficiaryMobilePhone").prop("disabled", false);
      $("#beneficiaryEmail").prop("disabled", false);
    },
    deleteBeneficiary() {
      this.showModalPopup(
        "modal_popup_actionRequest",
        false,
        null,
        this.action
      );
      this.showModalPopup("modal_popup_loading", true, null, this.action);
      this.actionError = null;
      let beneficiaryData = this.beneficiary;
      beneficiaryData.mobile_phone = this.formData.mobilePhone;
      beneficiaryData.email = this.formData.email;
      httpServiceCustomer.customerService
        .deleteBeneficiary(
          this.customer.company_id,
          this.beneficiary.id,
          this.accessToken
        )
        .then(function(response) {
          return response;
        })
        .catch((error) => {
          this.actionError = { code: error.response.data.code };
          this.beneficiaryDataError = { code: error.response.code };
          this.showModalPopup("modal_popup_loading", false, null, this.action);
          this.showModalPopup("modal_popup_info", true, null, this.action);
        })
        .then((response) => {
          if (response.status == 200) {
            this.beneficiary = response.data;
            this.actionError = null;
            this.actionSuccess = true;
            this.successMessage = this.$t("deleteDataSucceess");
            this.showModalPopup(
              "modal_popup_loading",
              false,
              null,
              this.action
            );
            this.showModalPopup("modal_popup_info", true, null, this.action);
            this.$emit("personChanged");
            // window.location.reload();
          } else if (response.status == 400) {
            this.beneficiaryDataError = { code: response.code };
          } else {
            this.beneficiaryDataError = { code: response.code };
          }
        });
    },
    submitBeneficiaryDetails() {
      this.showModalPopup(
        "modal_popup_actionRequest",
        false,
        null,
        this.action
      );
      this.showModalPopup("modal_popup_loading", true, null, this.action);
      this.actionError = null;
      let beneficiaryData = this.beneficiary;
      beneficiaryData.mobile_phone = this.formData.mobilePhone;
      beneficiaryData.email = this.formData.email;
      httpServiceCustomer.customerService
        .modifyBeneficiaryData(
          this.customer.company_id,
          {
            id: this.beneficiary.id,
            last_name: this.formData.lastName,
            first_name: this.formData.firstName,
            mobile_phone: this.formData.mobilePhone,
            email: this.formData.email,
            active:
              this.action === "INACTIVATE"
                ? false
                : this.action === "ACTIVATE"
                ? true
                : this.beneficiary.active
          },
          this.accessToken
        )
        .then(function(response) {
          return response;
        })
        .catch((error) => {
          return error.response;
        })
        .then((response) => {
          if (response.status == 200) {
            this.beneficiary = response.data;
            this.actionError = null;
            this.actionSuccess = true;
            this.successMessage = this.$t("modifiyDataSucceess");
            this.editMode = false;
            this.resetBeneficiaryDetails();
            this.showModalPopup(
              "modal_popup_loading",
              false,
              null,
              this.action
            );
            // this.showModalPopup("modal_popup_info", true, null, this.action);
              this.$emit("personChanged");

            // window.location.reload();
          } else if (response.status == 400) {
            console.log(response);
            this.beneficiaryDataError = { code: response.data.error_code };
          } else {
            this.beneficiaryDataError = { code: response.data.error_code };
          }

          // this.actionError = { code: error.response.data.code };
          // this.beneficiaryDataError = { code: error.response.code };
          this.showModalPopup("modal_popup_loading", false, null, this.action);
          this.showModalPopup("modal_popup_info", true, null, this.action);
        });
    },
    resetBeneficiaryDetails() {
      this.formData = {
        lastName: this.beneficiary.last_name,
        firstName: this.beneficiary.first_name,
        mobilePhone: this.beneficiary.mobile_phone,
        email: this.beneficiary.email
      };
      this.editMode = false;
      $("#beneficiaryFirstName").prop("disabled", true);
      $("#beneficiaryLastName").prop("disabled", true);
      $("#beneficiaryMobilePhone").prop("disabled", true);
      $("#beneficiaryEmail").prop("disabled", true);
    },
    selectHistoryDetailsMobile(index) {
      this.selectedHistoryDetails = this.filterHistory[index];
      this.detailsMobile = !this.detailsMobile;
    },
    changeReverse() {
      this.reverse = !this.reverse;
    },
    getValueForSort(entry) {
      switch (this.sortBy) {
        case "product":
          return this.getProductNameMethod(entry[this.sortBy]);
        case "order_type":
          return this.$t("orderType_" + entry.order_type);
        default:
          return entry[this.sortBy];
      }
    },
    onlyAlphabetCharacters(variable) {
      if (/^[a-zA-Z-]*$/i.test(variable)) {
        return variable;
      } else {
        return variable.replace(/[0-9&\/\\|`#@\[\]\,+=()$~%.'":*!?<>{}]/g, "");
      }
    },
    onlyNumericCharacters(variable) {
      if (/^[0-9]*$/i.test(variable)) {
        return variable;
      } else {
        return variable.replace(
          /[a-zA-Z&\/\\|`#@\[\]\-,+=()$~%.'":*!?<>{}]/g,
          ""
        );
      }
      return variable;
    },
    updateInputCharacters() {
      this.formData.lastName = this.onlyAlphabetCharacters(
        this.formData.lastName
      );
      this.formData.firstName = this.onlyAlphabetCharacters(
        this.formData.firstName
      );
      this.formData.mobilePhone = this.onlyNumericCharacters(
        this.formData.mobilePhone
      );
      this.beneficiary.security_code = this.onlyNumericCharacters(
        this.beneficiary.security_code
      );
      this.$forceUpdate();
    },
    refreshPage() {
      this.beneficiary = null;
      this.getBeneficiaryDetails();
    },
    getClassForCardStatus(status) {
      switch (status) {
        case "A":
          return "background-active";
        case "B":
        case "F":
        case "RC":
          return "background-blocked";
        case "D":
          return "background-deactivated";
        case "C":
        case "X":
        case "RNN":
          return "background-inactive";
        case "J":
        case "L":
          return "background-cancelled";
        case "E":
        case "I":
        case "M":
        case "N":
        case "O":
        case "P":
        case "R":
        case "S":
        case "RN":
        case "RNV":
        case "RNY":
        case "RP":
          return "background-will-expire";

        default:
          return "background-active";
      }
    },
    accountHasReissueRequest(account, requestType){
      if (account.card){
        if ( account.card.events){
          for (let indexC in account.card.events) {
              if (
                account.card.events[indexC].type  === requestType
              ) {
                return true;
              }
            }
          }
        }
      return false;
    }
  },
  computed: {
    filterHistory: function() {
      var returnHistoryDetails = this.historyDetails;
      const result = returnHistoryDetails.sort((a, b) =>
        this.getValueForSort(a) < this.getValueForSort(b)
          ? -1
          : a[this.sortBy] !== b[this.sortBy]
      );
      return this.reverse ? result.reverse() : result;
    },
    noOfPinRequests() {
      let count = 0;
      for (let index in this.beneficiary.accounts) {
          if (
            this.accountHasReissueRequest(this.beneficiary.accounts[index], 'RP') 
          ) {
            count++;
          }
      }
      return count;
    },
    noOfCardRequests() {
      let count = 0;
      for (let index in this.beneficiary.accounts) {
          if (
            this.accountHasReissueRequest(this.beneficiary.accounts[index], 'RC')  ||
            this.accountHasReissueRequest(this.beneficiary.accounts[index], 'RV') 
          ) {
            count++;
        }
      }
      return count;
    },
    noOfPhysicalCardRequests() {
      let count = 0;
      for (let index in this.beneficiary.accounts) {
          if (
            this.accountHasReissueRequest(this.beneficiary.accounts[index], 'RM') 
          ) {
            count++;
          }
      }
      return count;
    }
  },
  created() {
    this.accessToken = Cookies.get("token");
    this.getBeneficiaryDetails();
    this.getBeneficiaryHistory();

  },
  mounted() {
    this.config = JSON.parse(sessionStorage.getItem("mobile_jsonFile"));
    this.$gtm.trackView(
      "Beneficiary details",
      "/beneficiaries/beneficiary_details"
    );
  }
};
</script>
<style scoped src="@/assets/styles/beneficiary.css"></style>
<style scoped>
.clear-none {
  clear: none;
}
.notification-outline {
  display: block;
  width: 30%;
  padding-left: 55px;
  padding-right: 55px;
  padding-top: 12px;
  height: auto;
  padding-bottom: 12px;
  background: #fff;
  color: #f49400;
  border: 1px solid #f49400;
  text-decoration: none;
  border-radius: 5px;
  border-bottom: 2px solid #ddd;
  text-transform: uppercase;
  text-align: center;
  margin: 10px auto;
  cursor: pointer;
}


.beneficary-details-page .custom-notification-action {
  display: inline-block;
  width: auto;
}

.beneficary-details-page .left {
  display: inline-block;
  width: 50%;
}

.beneficary-details-page .right {
  display: inline-block;
  width: 50%;
}

.beneficary-details-page .right .notification-action {
  margin-left: 10px;
}

.resetButton:not([href]):not([tabindex]) {
  text-decoration: underline;
  color: #585858;
  font-style: italic;
  cursor: pointer;
}

.benef {
  position: relative;
  z-index: 9;
}
.benef-inactive {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(78, 91, 89, 0.9);
  z-index: 99;
}

.disabled {
  background-color: #9b9b9b !important;
}

@media (max-width: 1003px) {
  .beneficary-details-page {
    width: 95%;
    margin: 0 auto;
  }
}
@media (max-width: 768px) {
  .notification-outline {
    display: block;
    width: 100%;
    padding: 0px;
    padding-top: 12px;
    height: auto;
    padding-bottom: 12px;
    background: #fff;
    color: #f49400;
    border: 1px solid #f49400;
    text-decoration: none;
    border-radius: 5px;
    border-bottom: 2px solid #ddd;
    text-transform: uppercase;
    text-align: center;
    margin: 10px auto;
  }

  .grey-card .notification-outline {
    width: auto;
    margin: 10px;
    display: inline-block;
    padding: 10px;
  }
  .beneficary-details-page .custom-notification-action {
    display: inline-block;
    width: auto !important;
    padding: 10px;
    background-image: none;
  }
}
</style>
